function chatBotLoader(a) {   
    if (a != undefined) {
        (function (d, w, c) {
            if (!d.getElementById("spd-busns-spt")) {
                var n = d.getElementsByTagName('script')[0], s = d.createElement('script'); var loaded = false; s.id = "spd-busns-spt"; s.async = "async"; s.setAttribute("data-self-init", "false"); s.setAttribute("data-init-type", "opt"); s.src = 'https://cdn.au-freshbots.ai/assets/share/js/freshbots.min.js'; s.setAttribute("data-client", "0689afa9daac719c476c955e46151430f4f62919"); s.setAttribute("data-bot-hash", "c318b57580f5a34198ad26bec892d2889581e3d6"); s.setAttribute("data-env", "prod"); s.setAttribute("data-region", "au");
                if (c) {
                    s.onreadystatechange = s.onload = function () {
                        if (!loaded) {
                            c();
                        }
                        loaded = true;
                    };
                }
                n.parentNode.insertBefore(s, n);
            }
        })
            (document, window, function () {
                Freshbots.initiateWidget({
                    autoInitChat: false,
                    getClientParams: function () { return; }
                },
                    function (successResponse) { }, function (errorResponse) { });
            });
    }
}

    function chatBotPatient(a) {
        if (a != undefined) {
            (function (d, w, c) { if (!d.getElementById("spd-busns-spt")) { var n = d.getElementsByTagName('script')[0], s = d.createElement('script'); var loaded = false; s.id = "spd-busns-spt"; s.async = "async"; s.setAttribute("data-self-init", "false"); s.setAttribute("data-init-type", "opt"); s.src = 'https://cdn.au-freshbots.ai/assets/share/js/freshbots.min.js'; s.setAttribute("data-client", "0689afa9daac719c476c955e46151430f4f62919"); s.setAttribute("data-bot-hash", "717cadf28bbc49b2ffea8b20ea3dbc93374385c6"); s.setAttribute("data-env", "prod"); s.setAttribute("data-region", "au"); if (c) { s.onreadystatechange = s.onload = function () { if (!loaded) { c(); } loaded = true; }; } n.parentNode.insertBefore(s, n); } })(document, window, function () { Freshbots.initiateWidget({ autoInitChat: false, getClientParams: function () { return; } }, function (successResponse) { }, function (errorResponse) { }); });
        }
    }

    function chatBotProvider(a) {
        if (a != undefined) {
            //document.getElementById("spd-busns-spt").remove();
            //chatBotEmpty('a')
            (function (d, w, c) { if (!d.getElementById("spd-busns-spt")) { var n = d.getElementsByTagName('script')[0], s = d.createElement('script'); var loaded = false; s.id = "spd-busns-spt"; s.async = "async"; s.setAttribute("data-self-init", "false"); s.setAttribute("data-init-type", "opt"); s.src = 'https://cdn.au-freshbots.ai/assets/share/js/freshbots.min.js'; s.setAttribute("data-client", "0689afa9daac719c476c955e46151430f4f62919"); s.setAttribute("data-bot-hash", "dc8d04b17478287d4a1ca2edae021022d0bffc12"); s.setAttribute("data-env", "prod"); s.setAttribute("data-region", "au"); if (c) { s.onreadystatechange = s.onload = function () { if (!loaded) { c(); } loaded = true; }; } n.parentNode.insertBefore(s, n); } })(document, window, function () { Freshbots.initiateWidget({ autoInitChat: false, getClientParams: function () { return; } }, function (successResponse) { }, function (errorResponse) { }); });
        }
    }
    
    function chatBotEmpty(a) {
        if (a != undefined) {
            var head = document.getElementById("spd-busns-spt");
                      if(head)
                      {
                       
                          if(head.hasAttribute("data-bot-hash"))
                          {

                              var attr = head.getAttribute("data-bot-hash");
                              
                              if(attr == "c318b57580f5a34198ad26bec892d2889581e3d6")
                              {
                                head.setAttribute("data-bot-hash", "717cadf28bbc49b2ffea8b20ea3dbc93374385c6");
                                (document, window, function ()
                                { 
                                  Freshbots.initiateWidget({ autoInitChat: false,
                                     getClientParams: function ()
                                      { return; }
                                     },
                                      function (successResponse) { }, function (errorResponse) { }); }); 
                              }
                          }
                      }

            //document.getElementById('spd-busns-spt').remove()
        }
    }
    var varchatBotPatient;
    var varchatBotProvider;
    var varchatBotLoader;
    var varchatBotEmpty;
    